import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreLoader from "../components/preloader/Preloader";
const Home = lazy(() => import("../pages/Home"));
const Terms = lazy(() => import("../pages/Terms"));
const Config = () => {
  return (
    <Router>
      <Suspense fallback={<PreLoader />}>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/term-and-condition" element={<Terms />}></Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
